@import "../../../scss/_base.scss";

// Template level styles and overrides

body.quadrado {
	font-family: "Prompt", sans-serif !important;

	// CSS OVERRIDE FOR WEBPLAYER
	.vtn-info-cntainr-div .vtn-info-desc-txt div,
	.vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-title,
	.vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-views,
	.vtn-webplyr-tltxt-container .vtn-webplyr-tltxt-category,
	.vtn-share-cntainr-div .vtn-share-holder-div .vtn-share-share-text-div,
	.vtn-share-cntainr-div .vtn-share-holder-div .vtn-share-copied-txt {
		font-family: "Prompt", sans-serif !important;
	}

	.vt-input,
	.plans-listing .plan-box,
	.vt-select {
		font-family: "Prompt", sans-serif !important;
	}

	.vt-btn {
		font-family: "Prompt", sans-serif !important;
		border-radius: 9px;
		font-weight: 700;
		line-height: 1;
		font-size: 1.2rem;
		margin: 0 0.6rem 0 0;
		padding: 0.5rem 1rem; // 0.7rem 1.2rem 0.7rem; for build automation partners
		letter-spacing: 0; //0.03 for for build automation partners
		margin-left: 0;
		margin-right: 0;
		width: fit-content;

		&.icon-btn {
			padding-left: 2.05rem;

			svg {
				top: 0.73rem;
				left: 0.8rem;
				width: 1.1rem;
			}
		}

		&.create-account-btn,
		&.login-account-btn {
			font-weight: 400;
			border-radius: 9px !important; //not required for build automation partners; should default to 0.2 rem. Styles are below
		}

		@include media-down(md) {
			border-radius: 8px;
			min-width: 100px;
			font-size: 0.8rem;
			padding: 0.5rem 1.2rem 0.4rem;

			&.icon-btn {
				padding-left: 1.85rem;

				svg {
					width: 0.85rem;
					top: 0.34rem;
					left: 0.87rem;
				}
			}
		}
		&.btn-md{
			font-size: 0.9rem;
		}

		&.btn-sm {
			border-radius: 5px;
			min-width: 0;
			font-size: 0.75rem;
			padding: 0.375rem 1rem 0.35rem;
			letter-spacing: 0;

			&.icon-btn {
				padding-left: 1.6rem;

				svg {
					width: 0.7rem;
					top: 0.34rem;
					left: 0.7rem;
				}
			}

			&.width-50 {
				padding: 12px;
				font-size: 0.75rem;
				margin-bottom: 10px;
				width: 50%;
				text-transform: uppercase;

				@include media-down(md) {
					margin-bottom: 5px;
				}
			}

			@include media-down(md) {
				min-width: 70px;
				font-size: 0.65rem;
				padding: 0.37rem 0.7rem 0.2rem;

				&.icon-btn {
					padding-left: 1.4rem;

					svg {
						width: 0.65rem;
						top: 0.3rem;
					}
				}
			}
		}

		&.regular {
			background-color: $c-botton-color !important;

			&:hover {
				color: $c-botton-text-color !important;
			}
		}

		&.callout-btn-link {
			font-weight: 600;
		}

		&.rounded-lg {
			border-radius: 0.5625rem;
		}

		&.rounded-md {
			border-radius: 0.2rem;
		}

	}

	.widget-title {
		margin-bottom: 1rem;
		color: $c-accent;

		h2,
		h1,
		h3 {
			font-size: 1.3rem;
			font-weight: 700;
			font-family: "Prompt", sans-serif;
			margin-left: 0;
			margin-bottom: 0;
			line-height: 1;
			text-transform: uppercase;
		}
	}

	.hybrid-list-card-l2,
	.hybrid-list-card-l4 {
		.content-wrpr {
			.title {
				font-size: 0.9rem;
			}
		}
	}

	.form-control::placeholder,
	.form-control::-webkit-input-placeholder,
	.form-control {
		border: none;
		color: $c-text-secondary-d30;
	}

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="phone"] {
		border-bottom: 1px solid $c-text-primary;
		background-color: transparent;
		padding-left: 0rem;

		&:focus {
			outline: none;
			outline-style: none;
			box-shadow: none;
			border-color: transparent;
			border-bottom: 1px solid $c-accent;
		}
	}

	input[type="phone"] {
		padding-left: 1rem;
	}

	select {
		border: none;
		border-bottom: 1px solid $c-text-primary;
		background-color: transparent;
		padding-left: 0rem;

		&:focus {
			border-bottom: 1px solid $c-accent;
			outline: none;
		}
	}

	.form-group {
		margin-bottom: 1.5rem;
	}

	.pf-form-l2 {
		.pf-form-l2-form {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 3%;

			.form-group {
				flex-basis: 45%;

				&.form-action-button-container {
					flex-basis: 100%;
					margin-top: 1.5rem;
				}
			}
		}
	}

	.item-box-wrpr {
		.item-box {
			&.plan-box-default {
				margin-bottom: 1.2rem;

				&.plan-box-detailed {
					border: none;
					background-color: $c-background-l17;
					height: auto;
					border-radius: 0.3rem;

					.plan-name {
						font-size: 1.1rem;
						font-weight: 700;
						line-height: 1;
						text-transform: uppercase;
						color: $c-text-primary;
						margin-bottom: 0.5rem;
					}

					.plan-description {
						font-size: 0.7rem;
						color: $c-text-secondary;
						margin: 1rem 0rem;
						text-align: left;
						padding: 0 0.8rem;
					}

					.plan-action-btns-container {
						margin: 1rem 0rem;

						.plan-selection-btn {
							font-size: 0.75rem;
							padding: 1rem 1.7rem;
							border-radius: 0.2rem;
						}
					}

					.plan-price,
					.plan-icon {
						position: unset;
						padding: 0.7rem;
						display: none;
					}
				}

				//fix for overlapping current plan marker ~ bug only applies to quadrado
				.current-plan {
					position: unset;
				}
			}
		}
	}

	.additional-plan-details {
		.additional-plan-details-btn {
			font-size: 0.9rem;
			border-radius: 0.2rem;
			color: $c-accent;
		}
	}

	.container {
		@include media-up("md") {
			max-width: 775px;
		}

		@include media-up("lg") {
			max-width: 975px;
		}

		@include media-up("xl") {
			max-width: 1155px;
		}

		@media screen and (min-width: 1350px) {
			max-width: 1300px;
		}

		@media screen and (min-width: 1550px) {
			max-width: 1470px;
		}

		@media screen and (min-width: 1850px) {
			max-width: 1670px;
		}
	}

	.button-lyblynx {
		span {
			width: 100%;
			text-align: center;
		}
	}

	::-webkit-scrollbar {
		width: 0.2em;
	}

	::-webkit-scrollbar-track {
		background: $c-text-primary;
		border-radius: 100vw;
		margin-block: .4em;
	}

	::-webkit-scrollbar-thumb {
		background: $c-text-secondary;
		border: .25em $c-text-primary;
		border-radius: 100vw;
	}

	::-webkit-scrollbar-thumb:hover {
		background: $c-accent;
	}
}



body.quadrado.inter {
	font-family: "Inter", sans-serif !important;

	.vt-input,
	.plans-listing .plan-box,
	.vt-select {
		font-family: "Inter", sans-serif !important;
	}

	.vt-btn {
		font-family: "Inter", sans-serif !important;
		font-weight: 700 !important;
	}

	.widget-title {

		h2,
		h1,
		h3 {
			font-family: "Inter", sans-serif;
		}
	}
}