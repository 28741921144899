@import "../base.scss";

.earth .social-links {
	min-height: 100px;
	.all-links a.social-icon {
        margin-right: 34px;
    }
	a.social-icon {
		
		&.facebook::before {
			font-family: "Font Awesome 5 Brands", sans-serif;
			content: "\f39e";
		}
		&.twitter::before {
			font-family: "Font Awesome 5 Brands", sans-serif;
			content: "\f099";
		}
		&.instagram::before {
			font-family: "Font Awesome 5 Brands", sans-serif;
			content: "\f16d";
		}
	}
	@include media-down('sm') {
		.all-links a.social-icon {
			margin-right: 15px;
		}
	}
}