@import '../../../scss/base.scss';

body.jupiter {
	font-family: 'Poppins', sans-serif !important;
	$border-radius: 8px;
	.widget-title h2 {
		font-size: 1.2rem;
		font-family: 'Poppins', sans-serif !important;
		color: $c-accent;
	}
	.vt-btn,
	.vt-input {
		font-family: 'Poppins', sans-serif !important;
	}

	.video-horizontal-listing .widget-title, 
	.show-horizontal-listing .widget-title, 
	.movie-horizontal-listing .widget-title,
	.hybrid-horizontal-listing .widget-title {
		position: relative;
		&::before {
			display: block;
			position: absolute;
			content: "";
			width: 100%;
			height: 2px;
			background-image: linear-gradient(to left, rgba(0,0,0,0), $c-text-secondary-f50, rgba(0,0,0,0));
			top: 55%;
			left: 0
		}
		h2 {
			$padding: 7px;
			position: relative;
			font-size: 1rem;
			font-weight: 400;
			letter-spacing: 0.1rem;
			font-family: 'Poppins', sans-serif !important;
			padding-top: 5px;
			margin: 0 auto 12px;
			text-align: center;
			text-transform: uppercase;
			width: fit-content;
			padding-left: $padding;
			padding-right: $padding;
			max-width: 80%;
			background-color: $c-background;
		}
	}
	
	.vt-btn {
		font-family: 'Poppins', sans-serif;
		border-radius: $border-radius;
	}
	.search-form form div.input-group {
		.search-box {
			border-radius: $border-radius;
			border: none;
			background-color: $c-text-secondary-f50;
			padding-right: 31px;
		}
		.search-button {
			background-color: rgba(0,0,0,0);
			width: 33px;
		}
	}

	.movie-details-card {
		@include media-up('lg') {
			margin-bottom: 70px;
			min-height: 360px;
		}
	}

	.plans-listing {
		.plan-box {
			font-family: 'Poppins', sans-serif;
		}
	}

	//header stuff
	.site-header, .site-header-v2 {
		.app-logo {
			width: 130px;
			height: 33px;
		}
		.nav-item .nav-link.true-link:hover:after, 
		.nav-item .nav-link.true-link.active:after {
			display: none;
		}
		.nav-item .nav-link.true-link:hover,
		.nav-item .nav-link.true-link.active {
			color: $c-accent;
		}
		@include media-up('lg') {
			.vt-btn {
				min-width: 55px;
				font-size: .7rem;
				margin: 0 6px;
				padding: .7rem 1.2rem;
			}
			.navbar-brand {
				margin-right: 2.4rem;
			}
			.app-logo {
				width: 200px;
				height: 51px;
			}
			.search-form {
				.form-inline {	
					position: relative;
					width: 260px;
				}
				form div.input-group {
					width: 80%;
					margin: 0 6px;
					&:focus-within {
						width: 100%;
					}
					position: absolute;
					right: 0;
				}
			}
			
			
			.all-links {
				min-width: auto;
				height: 41px;
				background-color: $c-text-secondary-f50;
				border-radius: $border-radius;
				
				.nav-item .nav-link {
					margin: 3px 3px;
					padding-right: .25rem;
					padding-left: .25rem;
				}
			}
			.dropdown-menu {
				background-color: $c-text-secondary-d30;
				border-radius: $border-radius;
				a, span {
					font-weight: 600;
				}
				&.sub-link {
					top: 146%;
				}
			}
			.user-profile-button .dropdown-menu {
				left: -5.5em
			}
		}
		@include media-up('xl') {
			ul.main-nav{
				display: block;
				margin: auto;
			}
			.all-links .nav-item .nav-link {
				margin: 3px 10px;
				padding-right: .5rem;
				padding-left: .5rem;
			}
		}
		
	}
	
	//carousel UI
	.video-carousel .carousel-control-prev span::before,
	.video-carousel .carousel-control-next span::before {
		padding-top: 16px;
		padding-left: 3px;
		display: block;
		width: 34px;
		height: 34px;
		background-color: var(--botton-color);
		border-radius: $border-radius;
		color: var(--botton-text-color);
		font-size: 1.8rem;
	}

	.carousel-indicators li {
		width: 5px;
		height: 5px;
		border-radius: 2px;
	}

	.cnhl.carousel-control-prev span, 
	.cnhl.carousel-control-next span, 
	.chl.carousel-control-prev span, 
	.chl.carousel-control-next span, 
	.mhl.carousel-control-prev span, 
	.mhl.carousel-control-next span,
	.shl.carousel-control-prev span, 
	.shl.carousel-control-next span,
	.vhl.carousel-control-prev span, 
	.vhl.carousel-control-next span {
		font-size: 28px;
	}
	
	
	.video-details .video-actions {
		background-color: $c-text-secondary-d30;
		padding: 10px;
		border-radius: $border-radius;
		width: fit-content;
	}
	.watch-list-cntrls,
	.add-watch-over {
		.add-icon {
			background-color: $c-botton-color;
			svg {
				fill: $c-botton-text-color;
			}
		}
	}

	.movie-details-card {
		.video-genre,
		.video-cast-crew,
		.video-cast-crew .sec-text  {
			font-family: 'Poppins', sans-serif !important;
		}
	}

	
}

