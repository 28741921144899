@import "../../../../scss/_base.scss";

.mini-epg-row {
    height: 10.5rem;
    width: fit-content;
    padding-left: 5rem;
    display: flex;
    flex-direction: row;
    @include media-down('md') {
        padding-left: 3rem;
    }
    @include media-down('sm') {
        padding-left: 1.5rem;
        padding-bottom: 1rem;
    }
    @include media-down('xs') {
        padding-left: 1rem;
    }
}