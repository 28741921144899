@import "../../../scss/base";

.comment-box-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    .top-box-pannel {
        border-bottom: 1px solid $c-accent-f70;
        width: 100%;
        height: 2rem;
        .close-btn {
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            top: 0.3rem;
            right: 0.3rem;
            cursor: pointer;
            line-height: 0;
            svg {
                width: 100%;
                height: 100%;
                fill: $c-text-secondary;
            }
        }
        .box-title {
            line-height: 1;
            font-weight: 400;
            color: $c-text-primary;
            position: absolute;
            top: 0.42rem;
            left: 1rem;
            padding-left: 1.3rem;
            .red-dot {
                position: absolute;
                background-color: red;
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 50%;
                top: 0.15rem;
                left: 0;    
            }
        }
    }
    .box-inner {
        width: 100%;
        height: 0;
        flex: 1 1;
        position: relative;
        padding-top: 0.4rem;
    }
}