@import "../../../../scss/base.scss";

.page-level-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;

    &.open {
        display: block;
    }

    .popup-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        // max-width: 37.5rem;
        background-color: $c-background-l17;
        border-radius: 0.2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        min-height: 10rem;

        @include media-down('md') {
            width: 90%;
        }

        @include media-down('sm') {
            width: 100%;
        }

        .popup-header {
            padding: 1rem;
            border-bottom: 1px solid $c-text-secondary-d30;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .popup-title {
                font-size: 1.2rem;
                font-weight: bold;
                color: $c-text-primary;
                justify-self: start;
            }

            .popup-close {
                font-size: 1.2rem;
                font-weight: bold;
                color: $c-text-secondary;
                background-color: transparent;
                border: none;
                cursor: pointer;
                justify-self: end;


                &:hover {
                    color: $c-text-primary;
                }
            }
        }

        .popup-body {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .popup-step-label {
                font-size: 1rem;
                color: $c-text-secondary;
                margin-bottom:1rem;
            }

            .social-share {
                display: flex;
                flex-wrap: wrap;
                margin: 2rem 0 2rem 0;
                width: 100%;
                justify-content: center;

                .social-link-card {
                    margin-right: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    @include media-down('sm') {
                        margin-right: 0.5rem;
                        margin-bottom: 0.7rem;
                        width: 6rem;
                    }

                    @include media-down('lg') {
                        width: 5rem;
                    }

                    .icon-div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 3rem;

                        @include media-down('sm') {
                            margin-bottom: 0.5rem;
                        }

                        svg {
                            fill: white;

                            @include media-down('md') {
                                max-width: 4.7rem;
                                width: 4.7rem;
                            }
                        }
                    }

                    .social-link-label {
                        color: $c-text-primary;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                @include media-down('md') {
                    gap: 20px;
                    margin: 2rem 0 1rem 0;
                }
            }
        }

        .popup-footer {
            padding: 1rem;
        }
    }
}