@import "../../../scss/_base.scss";

.mini-epg-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;

	.epg-container {
		width: 100%;
		min-height: 10.5rem;
		position: absolute;
		top: -45%;
		transition: left .5s ease-in-out;
		left: 105%;
		display: none;

		&.show {
			display: block;
		}

		&.open {
			left: 0;
		}

		@include media-down('sm') {
			top: -50%
		}

		@include media-down('sm') {
			top: -52.5%
		}
	}

	.arrow-box {
		position: absolute;
		right: 1rem;
		width: 26px;
		height: 26px;
		top: -55%;
		cursor: pointer;

		svg {
			fill: $c-text-primary;
		}

		&:hover {
			svg {
				fill: $c-accent;
			}
		}

		@include media-down('md') {
			width: 20px;
			z-index: 10;
		}
	}
}