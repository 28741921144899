@import "../../../../scss/_base.scss";

.mini-epg-card {
    width: 55rem;
    height: 10.5rem;
    background-color: $c-surface-secondary-color-f80;
    margin-right: 1rem;
    position: relative;
    padding: 1.5rem 2rem 1.3rem;

    .card-inner {
        position: relative;
        width: 100%;
        height: 100%;

        .card-inner-top {
            width: 100%;
            height: 90%;
            display: flex;
            flex-direction: row;

            .card-inner-left {
                border-radius: 2px;
                height: 100%;

                img {
                    height: 100%;
                }
            }

            .card-inner-right {
                width: 100%;
                display: flex;
                flex-direction: row;

                .text-container-mini {
                    padding-left: 1.6rem;
                    color: $c-text-primary;
                    width: 65%;

                    .mini-epg-title {
                        margin-top: 0.5rem;
                        font-weight: 600;
                        font-size: 1.6rem;
                        margin-bottom: 0.5rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .mini-epg-duration {
                        font-size: 0.9rem;
                    }

                    @include media-down('xl') {
                        width: 55%;

                        .mini-epg-title {
                            font-size: 1.4rem;
                        }

                        .mini-epg-duration {
                            font-size: 0.8rem;
                        }
                    }

                    @include media-down('lg') {
                        width: 55%;

                        .mini-epg-title {
                            font-size: 1.2rem;
                        }

                        .mini-epg-duration {
                            font-size: 0.7rem;
                        }
                    }

                    @include media-down('md') {
                        width: 60%;

                        .mini-epg-title {
                            font-size: 1rem;
                        }

                        .mini-epg-duration {
                            font-size: 0.55rem;
                        }
                    }

                    @include media-down('sm') {
                        width: 55%;

                        .mini-epg-title {
                            font-size: 0.75rem;
                        }

                        .mini-epg-duration {
                            font-size: 0.5rem;
                        }
                    }

                    @include media-down('sm') {
                        width: 45%;

                        .mini-epg-title {
                            font-size: 0.65rem;
                        }
                    }
                }

                .button-holder {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    padding-right: 1rem;
                    width: 35%;

                    @include media-down('xl') {
                        width: 45%;
                    }

                    @include media-down('lg') {
                        .vt-btn {
                            font-size: 0.8rem;
                            padding: 0.5rem 1rem 0.5rem 2rem;

                            svg {
                                top: 0.4rem;
                                left: 0.7rem;
                                width: 0.9rem;
                            }
                        }
                    }

                    @include media-down('md') {
                        width: 45%;

                        .vt-btn {
                            font-size: 0.7rem;
                            padding: 0.35rem 0.8rem 0.3rem 1.7rem;

                            svg {
                                top: 0.25rem;
                                left: 0.7rem;
                                width: 0.8rem;
                            }
                        }
                    }


                    @include media-down('sm') {
                        width: 55%;

                        .vt-btn {
                            font-size: 0.7rem;
                            padding: 0.5rem;
                            padding-left: 1.4rem;

                            svg {
                                top: 0.4rem;
                                left: 0.5rem;
                                width: 0.8rem;
                            }
                        }
                    }

                    @include media-down('xs') {
                        width: 65%;

                        .vt-btn {
                            font-size: 0.6rem;
                            padding: 0.45rem;
                            padding-left: 1.2rem;

                            svg {
                                top: 0.4rem;
                                left: 0.5rem;
                                width: 0.6rem;
                            }
                        }
                    }
                }
            }
        }

        .card-inner-bottom {
            width: 100%;
            height: 10%;
            display: flex;
            align-items: end;
        }
    }

    .card-outer {
        position: absolute;
        top: 4rem;
        right: -3rem;
        z-index: 2;

        svg {
            fill: $c-accent;
            width: 3.3rem;
        }
    }

    @include media-down('xl') {
        width: 45rem;
        height: 8.5rem;
    }

    @include media-down('lg') {
        width: 37.5rem;
        height: 6.5rem;
        padding: 1rem 1.5rem 0.9rem;
    }

    @include media-down('md') {
        width: 32rem;
        height: 5.5rem;
        padding: 0.5rem 1.2rem 0.75rem;
    }

    @include media-down('sm') {
        width: 23rem;
        height: 4.5rem;
        padding: 0.3rem;
    }

    @include media-down('xs') {
        width: 20rem;
        height: 4.2rem;
        padding: 0.25rem;
    }
}