@import './_base.scss';

.site-body {
	min-height: 70vh;
	@include media-up('lg') {
		&.page-top-padding {
			padding-top: 3.4rem;
			&.overide-page-pad {
				padding-top: 0;
			}
		}
	}
}