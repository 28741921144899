@import "../../../../scss/_base.scss";

.channel-thumb {
	$square: 5.5rem;
	$square-xl: 4.8rem;
	$square-lg: 4.5rem;
	$square-md: 3.8rem;
	$square-sm: 3rem;
	$square-xs: 2.5rem;
	width: $square;
	height: $square;
	background-color: $c-surface-secondary-color-f50;

	// padding:1pxx;
	.channel-img {
		border-radius: 2px;
		width: 100%;

		img {
			width: 100%;
		}
	}

	@include media-down('xl') {
		width: $square-xl;
		height: $square-xl;
	}

	@include media-down('lg') {
		width: $square-lg;
		height: $square-lg;
	}

	@include media-down('md') {
		width: $square-md;
		height: $square-md;
	}

	@include media-down('sm') {
		width: $square-sm;
		height: $square-sm;
	}
	@include media-down('xs') {
		width: $square-xs;
		height: $square-xs;
	}
}