@import '../../../scss/_base.scss';
.dotloader {
    background-color: transparent;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    span {
        display: block;
        width:0.7rem;
        height:0.7rem;
        border-radius:50%;
        background-color:$c-text-primary;
        animation: dotloaderAnimation 1.5s infinite;
        &:nth-child(0){
            animation-delay: 0s;
        }
        &:nth-child(1){
            animation-delay: 0.5s;
        }
        &:nth-child(2){
            animation-delay: 1s;
        }
    }
    

    @keyframes dotloaderAnimation {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    &.background{
        background-color: $c-background-l17; 
    }
}