@import '../../../scss/base.scss';


.jupiter .site-footer {
	margin-top: 40px;
    background-color:$c-text-secondary-d30;
    .icon-link {
        color: $c-accent;
		&::before {
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: "Font Awesome 5 Free", sans-serif; 
			font-weight: 800; 
			font-size: 1.2rem;
			content: "\f007";
			margin-right: 8px;
    		position: relative;
    		top: 2px;
		}
	}
}