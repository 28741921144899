@import '../../../scss/base.scss';

.jupiter .footer-links {
    color: $c-text-primary;
    text-align: left;
	.all-links {
		margin-top: 22px;
		margin-bottom: 22px;
		padding: 0;
		&.two-clm{
			width: 50%;
		}
		.nav-item {
			margin-bottom: 15px;
			list-style: none;
			font-size: 0.9rem;
			a {
				color: $c-text-primary;
				&:hover {
					color: $c-accent;
				}
				&.big {
					font-weight: 600;
				}
				&.icon-link {
					&.play::before {
						font-family: "Font Awesome 5 Free", sans-serif;
						content: "\f04b";
						top: 0;
					}
				}
			}
		}
	
		@include media-down('md') {
			margin-top: 16px;
			margin-bottom: 16px;
			.nav-item {
				margin-bottom: 9px;
			}
		}
		@include media-down('sm') {
			margin-top: 11px;
			margin-bottom: 11px;
			.nav-item {
				margin-bottom: 5px;
			}
		}
	}
}