@import '../_base.scss';

.comment-field {
	position: relative;
	height: 100%;
	$height: 20px;

	.form-loader-wrapper {
		height: 100%;
		position: relative;

		.loader-bg {
			position: absolute;
			top: 0rem;
			left: 0rem;
			right: 0rem;
			bottom: 0rem;
			background-color: $c-background-f70;
			z-index: 7;
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				margin-left: 0.5rem;
				color: $c-accent;
				margin-top: 0.8rem;
			}
		}
	}

	.header-box {
		display: flex;
		position: relative;
		margin-bottom: 10px;
		$icon-square: 30px;
		$padding: 4px;
		padding: $padding;
		background-color: $c-background;
		@include media-down(lg) {
			padding-top: 5px;
			margin-bottom: 13px;
		}

		input {
			width: 80%;
			background: none;
			border: none;
			outline: none;
			padding: 4px;
			padding-right: 1000px;
			color: $c-text-primary;
			font-size: 0.9rem;
			height: $icon-square;
			@include media-down(xl) {
				width: 83%;
			}
			@include media-down(lg) {
				width: 90%;
			}
		}

		.emoji-icon {
			position: absolute;
			width: $icon-square;
			height: $icon-square;
			top: $padding;
			right: 30px;
			cursor: pointer;
			font-size: 1rem;
			padding-top: 3px;
			padding-left: 5px;
		}

		.user-box {
			width: $height;
			height: $height;
			margin: 3px 9px 0 6px;
			background-color: $c-botton-color;
			border-radius: 50%;
			position: relative;

			svg {
				position: absolute;
				top: 18%;
				left: 25%;
				width: 60%;
				height: 60%;
				fill: $c-botton-text-color;
			}
		}

		button {
			position: absolute;
			right: 8px;
			padding-right: 3px;
			background: none;
			border: none;
			font-family: 'Poppins', sans-serif !important;
			color: $c-text-primary;
			font-size: 0.9rem;
			font-weight: 600;
			line-height: 1.6;
			cursor: pointer;

			span {
				margin-right: 6px;
			}
		}

		svg {
			width: $height;
			fill: $c-text-primary;
		}
	}
}
