@import "../../../../scss/base";
.time-progress-bar {
	height: 0.15rem;
	width: 100%;
	background-color: $c-text-secondary;
	position: relative;
	margin-top: 0.2rem;

	.time-progress-bar-fill {
		height: 100%;
		background-color: $c-accent;
		position: absolute;
		top: 0;
		left: 0;
	}
}