@import '../../../scss/_base.scss';

.spinner-loader {
    border: 0.3rem solid transparent;
    border-top: 0.2rem solid $c-accent;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    animation: spinnerAnimation 1.5s infinite;
    
    &.sm {
        width: 1.5rem;
        height: 1.5rem;
    }
    &.xs {
        width: 0.7rem;
        height: 0.7rem;
        border-right: 0.2rem solid $c-accent;
    }
    &.onPrimary {
        border-top-color: $c-text-primary;
        border-right-color:$c-text-primary;

    }
}

@keyframes spinnerAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}