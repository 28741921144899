@import '../_base.scss';
body.venus {
	// template width override
	// commented out for now
	// .container {
	// 	@include media-up("md") {
	// 		max-width: 775px;
	// 	}
	// 	@include media-up("lg") {
	// 		max-width: 975px;
	// 	}
	// 	@include media-up("xl") {
	// 		max-width: 1155px;
	// 	}

	// 	@media screen and (min-width: 1350px) {
	// 		max-width: 1300px;
	// 	}
	// 	@media screen and (min-width: 1550px) {
	// 		max-width: 1470px;
	// 	}
	// 	@media screen and (min-width: 1850px) {
	// 		max-width: 1670px;
	// 	}
	// }

	@media only screen and (min-width: 1100px) {
		.navbar .search-form {
			width: 300px;
		}
	}
	.hybrid-horizontal-listing .widget-title {
		color: $c-accent;
	}
	
	.hybrid-grid-listing {
		.widget-title {
			margin-bottom: 30px;
			color: var(--text-secondary)
		}
	}
	.hybrid-details-card-l2 .left-col .description {
		color: var(--text-secondary);
		margin: 1rem 0;
		font-size: 0.85rem;
		line-height: 1.5;
	}
	.fb-google-login-button,.facebook-login-button {
		border-radius: 100px;
	}
	&.made-tommy { // An additional class gets added to meel-tv using which we will render 'made-tommy font' 
		font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		.widget-title h2 {
			font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		}
		.vt-input {
			font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		}
		.vt-btn {
			font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		}

		.carousel-video-card .caption-txt div {
			font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		}

		.plans-listing .plan-box {
			font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
		}
		.movie-details-card {
			.video-genre {
				font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
			}
			.video-cast-crew {
				font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
				.sec-text {
					font-family: "made tommy", 'Montserrat', "Roboto", sans-serif;
				}
			}
			
		}
		.search-form .suggestions .widget-title {
			h2 {
				font-family: "made tommy", 'Montserrat', "Roboto", sans-serif !important;
			}
		}
		
		
	}
}