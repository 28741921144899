@import "../../../scss/base";


.ventuno-player {
	position: relative;
	width: 100%;
	padding: 0;
	background-color: black;
	z-index: auto;
	.player-wrapper {
		position: absolute;
		width: 100%;
		top:0;
		left: 0;
		padding: 0;
		background-color: black;
	}
	.anim-transition {
		transition: width .3s ease-in-out, height .3s ease-in-out, top .3s ease-in-out, left .3s ease-in-out;
	}
	.side-pannel {
		position: absolute;
		top: 0;
		display: none;
		width: 0;
		left: 100%;
		&.show {
			display: block;
		}
	}

	&.landscape {
		.side-pannel {
			&.show {
				transition: width .4s ease-in-out, left .4s ease-in-out;
			}
			&.expand {
				width: 20%;
				left: 80%;
			}
		}
		.player-wrapper {
			&.show-sp {
				transition: width .4s ease-in-out;
				
			}
			&.expand-sp {
				width: 80%;
			}
		}
		@include media-down('xl') {
			.side-pannel.expand {
				width: 25%;
				left: 75%;	
			}
			.player-wrapper.expand-sp {
				width: 75%;
			}
		}
	
		@include media-down('lg') {
			.side-pannel.expand {
				width: 30%;
				left: 70%;	
			}
			.player-wrapper.expand-sp {
				width: 70%;
			}
		}
	
		@include media-down('md') {
			.side-pannel.expand {
				width: 35%;
				left: 65%;	
			}
			.player-wrapper.expand-sp {
				width: 65%;
			}
		}
	
		@include media-down('sm') {
			.side-pannel.expand {
				width: 40%;
				left: 60%;	
			}
			.player-wrapper.expand-sp {
				width: 60%;
			}
		}
	}

	&.portrait {
		.player-wrapper.show-sp,
		.side-pannel {
			width: 100%;
			left: 0%;
		}
		.side-pannel.show {
			height: 0;
			top: 100%;
			transition: height .4s ease-in-out, top .4s ease-in-out;
		}
		.player-wrapper.show-sp{
			transition: height .4s ease-in-out;
		}
	}
	.top-pannel {
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 0;
		display: none;
		.close-button {
			position: absolute;
			top:0.67rem;
			left:0.5rem;
			width: 2.3rem;
			height: 2.3rem;
			cursor: pointer;
			svg {
				fill: white;
				width: 100%;
				height: 100%;
			}
			@media (max-width: 400px) {
				width: 1.9rem;
				height: 1.9rem;
				top:0.67rem;
				left:0.5rem;
			}
		}
	}
	.comment-box-toggle {
		position: absolute;
		right: 2rem;
		bottom: 4rem;
		width: 3rem;
		height: 3rem;
		border-radius: 1.5rem;
		background-color: $c-accent;
		cursor: pointer;
		svg {
			position: absolute;
			top: 10%;
			left: 10%;
			width: 80%;
			height: 80%;
			fill: $c-text-primary;
		}
	}
}