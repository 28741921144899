@import '../../../scss/_base.scss';

.fullpageloder {
	background-color: $c-background;
	// width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	&.adopt-width {
		width: unset;
	}

	.message {
		color: $c-text-secondary;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
}