@import './_base.scss';
.product-quantity{
	margin-top: 3px;
	font-size: 0.9rem;
	color: $c-text-primary;
	
	.pr20{
		margin-right: 20px;
	}

	.qty-num{
		margin:0 8px;
		font-size: 1rem;
		font-weight: 600;
		color: $c-text-secondary;
	}
	.btn-qty{
		width:1.4rem;
		font-size: 1.4rem;
		line-height: 1;
		position: relative;
		bottom: 2px;
		padding: 0;
		fill: $c-text-secondary;
		&:hover {
			fill: $c-text-primary;
		}
		&.vt-disabld {
			color: $c-text-secondary-f50;
			cursor: default;
		}
		/*
		color: $c-text-primary;
		width: 24px;
		height: 24px;
		background: $c-text-secondary;
		display: inline-block;
		font-size: 20px;
		font-weight: 600;
		border-radius: 50%;
		padding-top: 1px;
		padding-left: 5px;
		margin:0 5px;
		line-height: 1;
		&.pq-min {
			padding-top: 0px;
			padding-left: 7px;
		}
		*/
	}
}