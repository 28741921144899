@import '../_base.scss';

.comment-box {
	$border: 1px solid $c-text-secondary-f50;
	$header-height: 40px;
	$padding: 8px;
	$s-padding: 5px;
	width: 100%;
	max-height: 100%;
	min-height: 98%;
	position: relative;
	@include media-down(lg){
		height: 300px;
		margin-top: 1.1rem;
	}
	@include media-down(sm) {
		margin-top: 0.8rem;
	}
	.box-header {
		height: $header-height;
		border-bottom: $border;
		position: relative;
		z-index: 1;
		.box-title {
			$border-radius: .25rem;
			border-top-left-radius: $border-radius;
			border-top-right-radius: $border-radius;
			color: $c-text-primary;
			width: fit-content;
			padding: 6px 10px;
			background-color: $c-surface-secondary-color; 
			position: absolute;
			left: 0;
			bottom: 0;
			font-size: 0.9rem;
			font-weight: 600;
			min-width: 90px; //Iphone changes!
		}
		.switch-text {
			font-size: 0.7rem;
			font-weight: 600;
			color: $c-text-secondary;
			position: absolute;
			right: 5px;
			top: 15px;
			cursor: pointer;
		}
	}
	&.full-screen {
		min-height: 100%;
		margin-top: 0;
		.box-body {
			padding: 0;
			.show-comment {
				display: flex;
				flex-flow: column;
				padding-top: 0;
				.comment-list {
					height: 0;
					flex: 1;
				}
				.comment-field-wrpr {
					height: 2.29rem;
				}
			}
		}
	}
	.box-body {
		position: absolute;
		padding: ($header-height + $padding) 0 $padding;
		top:0;
		left:0;
		height: 100%;
		width: 100%;
		$sqr: 15%;
		$s-sqr: 8%;
		@include media-down(md) {
			padding: ($header-height + $s-padding) $s-padding $s-padding;
		}
		.show-comment,
		.hidden-comment {
			position: relative;
			width: 100%;
			height: 100%;
		}
		.hidden-comment {
			.comments-icon {
				width: $sqr;
				height: $sqr;
				fill: $c-text-secondary-f50;
				position: absolute;
				left: calc((100 - #{$sqr}) / 2);
				top: calc(((100 - #{$sqr}) / 2) - 10);
				@include media-down(lg) {
					width: $s-sqr;
					height: $s-sqr;
					left: calc((100 - #{$s-sqr}) / 2);
					top: calc(((100 - #{$s-sqr}) / 2) - 10);
				}
			}
			.no-comments {
				font-size: 0.85rem;
				font-weight: 600;
				color: $c-text-secondary;
				position: absolute;
				width: 100%;
				text-align: center;
				top: calc(((100 - #{$sqr}) / 2) + 15);
				@include media-down(lg){
					top: calc(((100 - #{$s-sqr}) / 2) + 15);
				}
				@include media-down(md){
					top: calc(((100 - #{$s-sqr}) / 2) + 10);
				}
				@include media-down(sm){
					top: calc(((100 - #{$s-sqr}) / 2) + 8);
				}
			}
		}
		.show-comment {
			padding-top: 0.1rem;
			.comment-list {
				height: 92%;
				margin-bottom: 2%;
			}
			.comment-field-wrpr {
				position: relative;
				height: 8%;
				background-color: $c-surface-secondary-color;
			}
			@include media-down(xl) {
				.comment-list {
					height: 88%;
					margin-bottom: 1.5%;
				}
				.comment-field-wrpr {
					height: 12%;
				}
			}
			@include media-down(lg) {
				.comment-list {
					height: 85%;
					margin-bottom: 0.5%;
				}
				.comment-field-wrpr {
					height: 15%;
				}
			}
			input[type="text"] {
				padding: 0 0.3rem;
			}
		}
	}
}