@import "../../scss/_base.scss";

.vt-modal {
	.modal-content {
		background-color: $c-surface-secondary-color;
		min-height: 400px;
		position: relative;

		.modal-header {
			border: none;
		}

		.close-btn {
			width: 1.7rem;
			height: 1.7rem;
			position: absolute;
			top: 0.4rem;
			right: 0.4rem;
			line-height: 0;
			cursor: pointer;
			z-index: 1;
			svg {
				fill: $c-text-primary;
				width: 100%;
				height: 100%;
			}
		}

		.modal-body {
			padding: 2rem 0rem;
			display: flex;
    		justify-content: center;
			align-items: center;
			height: 100%;
		}

		.close {
			color: $c-accent;
		}
	}
}