@import './_base.scss';

.site-header {
	text-align: center;
	padding: 0 !important; // added important to fix bootsrap modal adding padding
	// height: 70px;
	// z-index: 99999;
	border-bottom: 1px solid $c-text-secondary-f50;
	background: $c-header;

	width: 100%;

	// margin-bottom:40px;
	@media screen and (min-width: 992px) {
		height: $header-height + px;
	}

	@include vt-transition(0.6s);

	&.scrolled {
		background: $c-background;
		min-height: 60px;

		.all-links {
			min-height: 60px;
		}
	}

	.navbar-icon {
		position: relative;
		overflow: hidden;

		.vt-btn-like {
			position: relative;

			&.vn-shake {
				/* Start the shake animation and make the animation last for 0.5 seconds */
				animation: shake 0.5s;
				/* When the animation is finished, start again */
				animation-iteration-count: infinite;
			}
		}

		.shop-icon {
			width: 50px;
			height: 50px;
			padding: 8px;
			color: $c-text-primary;
			text-align: center;
			line-height: 1.4;
			font-size: 1.6rem;
			margin-right: 8px;
		}

		.shop-item-count {
			line-height: 1.5;
			font-size: 0.7rem;
			font-weight: 600;
			position: absolute;
			text-align: center;
			width: 16px;
			height: 16px;
			background-color: $c-botton-color;
			border-radius: 50%;
			color: white;
			top: 5px;
			left: 21px;
		}
	}

	.user-icon {
		.vt.vt-usersolid {
			font-size: 30px;
			margin-left: 10px;
			color: $c-botton-color;
		}
	}

	.user-profile-button {
		.dd-name {
			display: none;

			&:hover {
				color: $c-accent;
			}

			color: $c-accent;
			padding: .25rem .4rem .5rem;
			margin-bottom: 6px;
			border-bottom: 1px solid var(--text-secondary-a50);

			cursor: default;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.dropdown-menu {
			width: 8rem;
			left: -6.7rem;
			@include media-down('lg') {
				width:auto;
			}
		}

		.user-icon .user-name {
			font-size: 0.8rem;
			position: relative;
			bottom: 4px;
			color: $c-accent;
		}

		@include media-up('lg') {
			.user-icon .user-name {
				display: none;
			}

			.dd-name {
				display: block;
			}
		}
	}

	.explore-button {
		font-weight: 400;

		.vt.vt-browse {
			font-size: 2rem;
			margin-left: 5px;
			vertical-align: inherit;
		}
	}

	// .start-trial-button {
	// 	// display: none !important;

	// }

	.search-form {
		margin-top: 0;

		form div.input-group:focus-within {
			width: 100%;
		}

		@include media-down('lg') {
			margin-top: 10px;

			form div.input-group:focus-within {
				width: 80%;
			}
		}


		&.suggestions {
			display: none;
		}
	}

	.dropdown-menu {
		background-color: $c-header;
		text-align: center;
		top: 135%;
		left: -3.2rem;

		@include media-up('lg') {
			width: 11.4rem;
		}

		@include media-down('lg') {
			padding-top: 0;
			margin-top: 0;
		}

		a,
		span {
			color: $c-header-text-color;
			white-space: pre-wrap;
			cursor: pointer;
			font-size: 0.85rem;
			font-weight: 600;
			background: none;
			line-height: 1.2;
			margin-bottom: 0.2rem;

			&:hover {
				color: $c-botton-color;
				background: none;
			}
		}

		&.sub-link {
			top: 156%;
		}
	}

	.app-logo-lg {
		width: 200px;

		@include media-down('lg') {
			width: 175px;
		}

		@include media-down('md') {
			width: 150px;
		}
	}

	.app-logo {
		width: 148px;
		height: 47px;
	}

	.navbar-toggler {
		.navbar-toggler-icon {
			font-size: inherit;
			background: inherit;
			// font-size: 40px;
		}

		.vt-menu:before {
			// padding-top: 10px;
			font-size: 30px;
			color: $c-header-text-color;
		}
	}

	.all-links {
		@include vt-transition(0.6s);
		min-width: 350px;

		.nav-item {
			position: relative;
			float: left;

			@include media-down('lg') {
				margin-bottom: 5px;
			}

			.nav-link {
				font-size: 0.95rem;
				font-weight: 600;
				margin: auto 0;
				color: $c-header-text-color;
				line-height: 1.2;
				// font-weight: 600;
				// text-decoration: none;

				&.babylove {
					font-family: babylove, sans-serif;
					font-size: 1.3rem;
					letter-spacing: 0.15rem;
					line-height: 0.9;
					font-weight: 400;
				}

				@include media-down('xl') {
					@include media-up('lg') {
						&.babylove {
							font-size: 1.1rem;
							line-height: 1;
						}
					}
				}

				&.true-link {

					&:hover,
					&.active {
						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							$border-width: 50%;
							width: $border-width;
							max-width: 50px;
							margin: auto;
							left: calc((100% - #{$border-width}) / 2);
							right: calc((100% - #{$border-width}) / 2);

							border-bottom: 3px solid $c-botton-color;
						}
					}
				}
			}
		}
	}
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}

	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}

	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}

	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}

	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}

	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}

	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}

	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}

	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}

	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}

	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}