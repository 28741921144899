@import "../_base.scss";

body.earth {
	font-family: "Open Sans", sans-serif !important;

	.vt-input,
	.plans-listing .plan-box,
	.vt-select {
		font-family: "Open Sans", sans-serif;
	}
	.vt-btn {
		font-family: "Open Sans", sans-serif;
		border-radius: 100px;
		font-weight: 600;
		border: 0;

		min-width: 100px;
		font-size: 0.7rem;
		margin: 0 0.6rem 0 0;
		padding: 0.4rem 1.4rem;
		letter-spacing: 0rem;

		@include media-down(md) {
			min-width: 100px;
			font-size: 0.6rem;
			margin: 0;
			padding: 0.4rem 1.2rem;
			letter-spacing: 0rem;
		}

		&.btn-sm {
			font-size: 0.6rem;
			margin: 0;
			padding: 0.5rem 0.4rem;
			letter-spacing: 0rem;
		}
		&.vt-play {
			position: relative;
			padding-left: 2.3rem;
			&::before {
				position: absolute;
				font-size: 0.8rem;
				font-family: "Font Awesome 5 Free", sans-serif;
				top: 0.3rem;
				left: 1.2rem;
				content: "\f04b";
			}
			@include media-down(md) {
				padding-left: 2rem;
				&::before {
					font-size: 0.7rem;
					top: 0.35rem;
					left: 0.9rem;
				}
			}
		}
	}
	.payment .card .cancel-button > .vt-btn {
		color: var(--accent-color) !important;
		font-size: 0.9rem;
		border: none !important;
		background: none !important;
	}

	.plans-listing .info_text .text-secondary {
		color: var(--accent-color) !important;
		font-size: 1rem;
		margin-right: 0.5rem;
		margin-top: 1rem;
		@include media-down("md") {
			font-size: 0.85rem;
			margin-top: 0.6rem;
			margin-right: 0;
			text-align: center !important;
		}
	}

	.vt-btn-link {
		color: $c-text-primary !important;
		border: 1px solid $c-text-primary-f50 !important;
		background-color: rgba(0, 0, 0, 0.05);
		font-size: 0.7rem;
		&:hover {
			color: $c-accent !important;
			// text-decoration: underline;
		}
		@include media-down("md") {
			font-size: 0.6rem;
		}
	}

	.widget-title h2 {
		font-size: 1.1rem;
		font-weight: 600;
		font-family: "Open Sans", sans-serif !important;
		color: $c-text-primary !important;
		margin-bottom: 14px;
		margin-left: 10px;
	}
	.container {
		@include media-up("sm") {
			max-width: 600px;
		}
		@include media-up("md") {
			max-width: 900px;
		}
		@include media-up("lg") {
			max-width: 1075px;
		}
		@include media-up("xl") {
			max-width: 1250px;
		}
	}
	@include media-up("md") {
		.video-carousel .carousel-sm {
			display: block;
		}
	}

	.video-carousel .carousel-sm {
		.carousel-control-next,
		.carousel-control-prev {
			width: 8%;
			@include media-down("md") {
				width: 13%;
			}
		}
	}

	/* show and card css overridews */
	.show-card .primary-thumb {
		border-radius: 0.75rem;
	}

	.hybrid-list-card-l2 {
		margin: 0% 0.5%;
		.primary-thumb {
			border-radius: 0.75rem;
		}
	}

	// .hit-area {
	// 	background-color: rgba(100,0,0,0.7);
	// }
	/* video grid listing overrides */
	.video-grid-listing {
		.widget-title {
			display: none;
		}
		.outer-wrap {
			padding-left: 23px;
			padding-right: 23px;
		}
	}

	/* Let's get this party started */
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: $c-text-primary-f50;
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background: $c-text-primary-f10;
	}

	/* EPG */
	@include media-up("lg") {
		.channel-epg-horizontal-listing .inner-wrpr {
			width: 70%;
			max-width: 1100px;
		}
	}
	.channel-epg-horizontal-listing .inner-wrpr .img-hldr {
		border-radius: 0.75rem;
		overflow: hidden;
	}

	.no-epg-card,
	.channel-epg-card {
		background-color: $c-text-primary-f10;
		.txt-wrpr .time-txt::before {
			top: 0.35rem;
		}
	}
	.new-episode {
		top: 1px;
		right: 1px;
		$border-radius: 0.65rem;
		$border-radius-sml: 0.45rem;
		border-top-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		padding: 0 8px;
		padding-top: 3px;
		min-width: 29px;
		height: 22px;

		@include media-down("lg") {
			@include media-up("md") {
				border-top-right-radius: $border-radius-sml;
				border-bottom-left-radius: $border-radius-sml;
				font-size: 0.5rem;
				height: 16px;
				padding-top: 2px;
			}
		}
	}
	.hybrid-horizontal-listing {
		.carousel-control-next,
		.carousel-control-prev {
			z-index: 2;
			span {
				height: 66px;
			}
		}
		.content-wrpr {
			.title {
				margin-top: 0.3rem;
				font-size: 0.9rem;
			}
		}
	}
	.live-video-details-card {
		margin: 0 4.37rem;
	}
}

//
