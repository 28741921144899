@import url("/css/ventunoFont.css");

.vt-sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.v-widget {
	margin-top: 20px;
	// border: 1px solid skyblue; 
}

.v-card {
	border: 0px;
	// border: 1px solid green;
	background-color: inherit;
}

.disabledbutton {
	pointer-events: none;
	opacity: 0.4;
}

.cursor-pointer {
	cursor: pointer;
}

h2 {
	font-size: 1.6rem;
}

.widget-title {
	&.title-center {
		text-align: center;
	}

	&.title-right {
		text-align: right;
	}

	color: $c-accent;

	h2 {
		font-size: 1.2rem;
		font-family: 'Montserrat', "Roboto", sans-serif;
	}

	&.babylove h2 {
		font-family: babylove, sans-serif;
		letter-spacing: 0.15rem;
		font-size: 1.5rem;
	}

	h2 {
		font-size: 1.2rem;
	}

	h3 {
		font-size: 1.1rem;
	}

	h4 {
		font-size: 1rem;
	}

	&.ttl-size-small {
		h2 {
			font-size: 0.9rem;
		}

		h3 {
			font-size: 0.8rem;
		}

		h4 {
			font-size: 0.7rem;
		}
	}

	&.ttl-size-large {
		h2 {
			font-size: 1.55rem;
		}

		h3 {
			font-size: 1.45rem;
		}

		h4 {
			font-size: 1.35rem;
		}
	}
}

.text-accent {
	color: $c-accent;
}

a,
a:hover {
	text-decoration: none;
	color: $c-accent;
}

a:hover {
	text-decoration: underline;
}

input {
	&::-ms-clear {
		display: none;
	}
}

.vt-select {
	@extend .vt-input;
	height: unset;
	padding: 5px 15px !important;
}

.vt-input {
	border-radius: 0;
	padding: 15px;
	font-size: .8rem;
	font-family: 'Montserrat', "Roboto", sans-serif;

	@include media-up(md) {
		padding: 15px;
		height: 52px;
		line-height: 24px;
		font-size: 1rem;

	}
}

.vt-description {
	color: $c-text-primary-f70;
}

.vt-btn-like {
	cursor: pointer;
}

.vt-btn {
	font-family: 'Montserrat', "Roboto", sans-serif;
	border-radius: 100px;
	font-weight: 600;
	border: 0;
	height: fit-content;
	// box-shadow:0 0 5px 5px rgba(0,0,0,0.2);
	// transition:.3s ease;
	position: relative;
	min-width: 80px;
	font-size: .6rem;
	margin: 0px 5px;
	padding: .6rem 1.1rem;
	letter-spacing: .03rem;
	line-height: 1;

	&.no-link {
		cursor: not-allowed !important;
	}

	&.icon-btn {
		padding-left: 2.2rem;

		svg {
			fill: $c-text-primary;
			width: 2rem;
			position: absolute;
			top: 1.73rem;
			left: 0.36rem;
		}
	}

	&.btn-sm {
		font-size: 0.6rem;
		padding: 0.5rem 1rem;
		letter-spacing: 0rem;
		min-width: 70px;
		margin: 0px 3px;

		&.icon-btn {
			padding-left: 1.3rem;

			svg {
				width: 0.8rem;
				top: 0.35rem;
				left: 0.6rem;
			}
		}
	}

	@include media-up(md) {
		min-width: 90px;
		font-size: .7rem;
		margin: 0px 10px;
		padding: .7rem 1.2rem;

		&.icon-btn {
			padding-left: 2.3rem;
		}
	}

	@include media-down(lg) {
		&.icon-btn svg {
			top: 1.58rem;
		}
	}

	&.vt-btn-danger {
		background-color: rgba(255, 0, 0, 0.7);
		color: white;
	}

}

.no-border-radius {
	border-radius: 0;
	color: $c-botton-text-color !important;
	background-color: $c-botton-color;

	&:hover {
		background-color: $c-botton-hover-color
	}

	&[disabled] {
		background-color: $c-botton-disabled-color
	}
}

.vt-btn-primary {
	//	 @extend .vt-btn;
	color: $c-botton-text-color !important;
	background-color: $c-botton-color;

	&:hover {
		background-color: $c-botton-hover-color
	}

	&[disabled] {
		background-color: $c-botton-disabled-color
	}

	&.btn-with-loader {
		display: flex;
		justify-content: center;
		align-items: center;

		&>* {
			margin-right: 1rem;
		}
	}
}

.vt-btn-secondary {
	color: $c-text-primary !important;
	background-color: $c-text-secondary;

	&:hover {
		background-color: $c-text-secondary-d15;
	}

	&[disabled] {
		background-color: $c-text-secondary-d30;
	}

	@include media-up(md) {
		&.vt-btn-icon {
			padding-left: 2.2rem;

			svg {
				position: absolute;
				top: 0.45rem;
				left: 1.2rem;
				width: 24px;
				height: 24px;
			}
		}
	}

	&.btn-with-loader {
		display: flex;
		justify-content: center;
		align-items: center;

		&>* {
			margin-right: 1rem;
		}
	}
}

.vt-btn-tertiary {
	background-color: transparent;
	color: $c-text-primary;

	svg {
		width: 24px;
		height: 24px;
		fill: $c-text-primary;
		margin-right: 0.6rem;
	}

	&:hover {
		background-color: $c-text-primary-f10;
		color: $c-text-primary;
	}
}

.vt-btn-primary,
.vt-btn-secondary {
	&.vt-btn-icon {
		padding-left: 2.2rem;

		svg {
			position: absolute;
			top: 0.5rem;
			left: 0.7rem;
			width: 18px;
			height: 18px;
			fill: $c-text-primary;
		}



		@include media-down(md) {
			padding-left: 1.9rem;

			svg {
				top: 0.45rem;
				left: 0.6rem;
				width: 14px;
				height: 14px;
			}
		}

	}
}


.vt-icon {
	position: relative;
	width: 51px;
	height: 51px;
	min-width: 51px;
	min-height: 51px;
	padding: 0;

	div {
		position: absolute;
		width: 26px;
		height: 26px;
		top: 12px;
		left: 12px;

		svg {
			fill: $c-text-primary;
		}
	}

	@include media-down(md) {
		padding-left: 46px;

		div {
			width: 20px;
			height: 20px;
			top: 8px;
			left: 18px;
		}
	}
}

.vt-btn-link {
	color: $c-accent !important;
	background-color: transparent;
	font-size: 1rem;

	&:hover {
		color: $c-accent-l25;
		// text-decoration: underline;
	}

	@media screen and (max-width:768px) {
		font-size: .7rem;
	}
}

input[type="email"],
input[type="text"],
input[type="password"] {
	border-radius: 0;
}

.text-accent {
	color: $c-accent !important;
}

.text-primary {
	color: $c-text-primary !important;
}

.text-secondary {
	color: $c-text-secondary !important;
}

.vt-modal {
	background-color: rgba(0, 0, 0, 0.5);

	.social-share-popup {
		max-width: 100%;

		// margin-left: 1rem;
		@include media-up('sm') {
			max-width: 50%;
			margin-left: auto;
		}

		@include media-up('lg') {
			max-width: 30%;
		}

		.modal-content {
			min-height: 10em;
		}

		.modal-body {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.widget-title {
	.title-center {
		text-align: center;
	}

	.title-right {
		text-align: right;
	}
}

.form-help-text {
	color: $c-text-secondary;
	margin: 0.4rem 0rem;
}

.loading-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flip180 {
	transform: rotate(180deg);
}

//seo elements
.background-seo-element {
	display: none;
	visibility: hidden;
}

a {
	&.fluid-link {
		width: 100%;
		position: absolute;
		left: 0px;
		height: 100%;
	}

	&.fluid-link-without-position {
		width: 100%;
		height: 100%;
	}

	&.fluid-link-inline {
		width: 100%;
		position: absolute;
		left: 0px;
		height: 100%;
		top: 0rem;
	}
}