@import './../../../scss/base';

.auto-device-connect{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .auth-success-display{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            color: $c-text-primary;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}