@import './_base.scss';

.search-form {
	// margin-top: 0px !important;
	form {
		div.input-group {
			width: 90%;
			margin: auto;
			
			-webkit-transition: width 0.4s linear;
			transition: width 0.4s linear;

			%search-box-items-cm {
				background-color: $c-background-f50;
				border-radius: 50px;
				color: $c-text-primary;
				&:focus {
					box-shadow: none;
				}
			}
			&.in-page {
				width: 50%;
				@include media-down(lg) {
					width: 70%;
				}
				@include media-down(md) {
					width: 85%;
				}
			}
			@include media-down(lg) {
				width: 70%;
			}
			
			.search-box {
				@extend %search-box-items-cm;
				border: 1px solid $c-text-secondary;
				border-radius: 30px !important; // important to override bootstrap default form control border radius style 
				height: 36px;
				font-size: 0.85rem;
				padding-right: 36px;
				@include media-up(md) {
					margin-top:5px;
					height: 41px;
				}
			}
			
			.search-button {
				@extend %search-box-items-cm;
				height: 34px;
				width: 34px;
				position: absolute;
				z-index: 3;
				top: 1px;
				right: 0.3rem;
				padding: 0.2rem;
				margin: 0;
				@include media-up(md) {
					height: 39px;
					width: 39px;
					top: 6px;
				}
				svg {
					fill: $c-text-primary;
				}
			}
			.search-list {
				width: 100%;
			}
		}
	}
}