@import "../../../../scss/_base.scss";

.mini-epg {
    height: 10.5rem;
    width: 100%;

    .channel-thumb-wrpr {
        position: absolute;
        left: 7.35rem;
        cursor: pointer;
    }

    .up-arrow-container,
    .down-arrow-container {
        position: absolute;
        width: 2.5rem;
        left: 9rem;
        z-index: 5;
        cursor: pointer;
        &:hover{
            svg{
                fill: $c-accent;
            }
        }

        svg {
            fill: $c-text-primary;
        }

    }

    .up-arrow-container {
        top: -2.5rem;
        rotate: 270deg;
    }

    .down-arrow-container {
        bottom: -2.5rem;
        rotate: 90deg;
    }

    @include media-down('xl') {
        .channel-thumb-wrpr {
            left: 7rem;

            &.below {
                top: 9rem !important;
            }
        }

        .up-arrow-container,
        .down-arrow-container {
            left: 8.5rem;
            width: 2rem;
        }

        .down-arrow-container {
            bottom: -0.3rem
        }
    }

    @include media-down('lg') {
        .channel-thumb-wrpr {
            left: 6.25rem;

            &.below {
                top: 7.5rem !important;
            }

            &.above {
                &.card-0 {
                    bottom: 11.25rem !important;
                }

                &.card-1 {
                    bottom: 16.25rem !important;
                }
            }
        }

        .up-arrow-container,
        .down-arrow-container {
            left: 7.85rem;
            width: 1.75rem;
        }

        .down-arrow-container {
            bottom: 1rem
        }
    }

    @include media-down('md') {
        .channel-thumb-wrpr {
            left: 4rem;

            &.below {
                top: 6rem !important;
            }
        }

        .up-arrow-container,
        .down-arrow-container {
            left: 5.25rem;
            width: 1.7rem;
        }

        .down-arrow-container {
            bottom: 2rem
        }
    }

    @include media-down('sm') {
        .channel-thumb-wrpr {
            opacity: 0.45 !important;
            left: 1.85rem;

            &.below {
                top: 5rem !important;
            }

            &.above {
                &.card-0 {
                    bottom: 10.95rem !important;
                }

                &.card-1 {
                    bottom: 14.65rem !important;
                }
            }
        }

        .up-arrow-container,
        .down-arrow-container {
            left: 2.9rem;
            width: 1rem;
        }

        .down-arrow-container {
            bottom: 4rem;
        }

    }

    @include media-down('xs') {
        .channel-thumb-wrpr {
            opacity: 0.55 !important;
            left: 1rem;

            &.below {
                top: 4.5rem !important;
            }

            &.above {
                &.card-0 {
                    bottom: 10.75rem !important;
                }

                &.card-1 {
                    bottom: 13.5rem !important;
                }
            }
        }

        .up-arrow-container,
        .down-arrow-container {
            left: 1.7rem;
            width: 1rem;
        }

        .down-arrow-container {
            bottom: 4rem;
        }

    }



}