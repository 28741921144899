@import './_base.scss';

$trash-icon-sqr: 18px;

.shopping-cart-card {
	width: 100%;
	margin-bottom: 20px;
	position: relative;

	.cart-item-image {
		img {
			width: 100%;
		}
	}

	.cart-item-name {
		color: $c-text-primary;
	}

	.cart-item-total {
		color: $c-text-secondary;
		font-weight: 600;
		font-size: 19px;
	}

	.cart-item-remove {
		position: absolute;
		top: 3px;
		right: 0;
		width: $trash-icon-sqr;
		height: $trash-icon-sqr;
		line-height: 1;

		svg {
			fill: $c-text-primary;
		}

		&:hover {
			svg {
				fill: $c-accent;
			}
		}
	}
}