@import '../_base.scss';

.video-comments {
	@include slim-scroll();
	scroll-behavior: smooth;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
	padding-right: 10px;
	padding-left: 10px;
	$picture-padding: 0.5rem;
	$picture-min-width: 1.4rem;
	$picture-min-height: 1.4rem;
	$picture-max-height: 1.5rem;
	$picture-max-width: 1.5rem;

	.video-message {
		min-height: 50px;
		height: fit-content;
		width: 100%;
		margin-bottom: 10px;
		$user-pic-width: 8%;
		$user-pic-margin: 4%;
		$user-pic-width-md: 5%;
		$user-pic-margin-md: 2%;
		position: relative;
		display: flex;

		.profile-container {
			padding-right: $picture-padding;
			padding-top: 4px;

			.profile-image {
				min-width: $picture-min-width;
				min-height: $picture-min-height;
				max-width: $picture-max-width;
				max-height: $picture-max-height;
				border-radius: 50%;
				overflow: hidden;
			}
		}

		.user-pic {
			// width: $user-pic-width;

			padding-top: 5px;

			@include media-down(lg) {
				@include media-up(sm) {
					width: 1rem;
					margin-right: $user-pic-margin-md;
				}
			}

			position: relative;

			.user-box {
				min-width: $picture-min-width;
				min-height: $picture-min-height;
				max-width: $picture-max-width;
				max-height: $picture-max-height;
				height: 0;
				padding-top: 100%;
				background-color: $c-botton-color;
				border-radius: 50%;
				position: relative;

				svg {
					position: absolute;
					top: 18%;
					left: 25%;
					width: 60%;
					height: 60%;
					fill: $c-botton-text-color;
				}
			}

		}

		.msg-area {
			width: 100%;

			@include media-down(lg) {
				@include media-up(sm) {
					// width: 100 - $user-pic-width-md - $user-pic-margin-md;
					padding-left: 10px;
				}

			}

			position: relative;
			$pad: 4px;
			padding: $pad;
			display: flex;
			flex-direction: column;

			.wrapper-info {
				padding: 10px 30px 10px 15px;
				background-color: $c-accent;
				border-radius: 0px 10px 10px 10px;
				width: 100%;

				.msg-name {
					width: 100%;
					min-width: 5rem;
					font-size: 0.9rem;
					font-weight: 600;
					line-break: anywhere;
					color: $c-botton-text-color;
					margin-bottom: 8px;
					line-height: 1.2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.msg-text {
					padding-right: 5px;
					font-size: 0.75rem;
					color: $c-text-primary;
					line-break: anywhere;
				}
			}

			.msg-time {
				color: $c-text-primary;
				font-size: 0.65rem;
				padding-left: 0.4rem;
			}

		}

		&.self-comments {
			justify-content: end;

			.msg-area {
				text-align: right;

				.wrapper-info {
					padding: 10px 15px 10px 30px;
					border-radius: 10px 0px 10px 10px;
					background-color: $c-surface-secondary-color;
				}

				@include media-down(lg) {
					@include media-up(sm) {
						padding-left:15px
					}
				}

			}
		}
	}
}