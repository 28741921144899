@import "../../../../scss/base.scss";

.popup-backdrop.popup-backdrop-v2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
    &.open {
        display: block;
    }
    .popup-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 37.5rem;
        background-color: $c-background-l17;
        border-radius: 0.2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        min-height: 10rem;
        .popup-header {
            padding: 1rem;
            border-bottom: 1px solid $c-text-secondary-d30;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .popup-title {
                font-size: 1.2rem;
                font-weight: bold;
                color: $c-text-primary;
            }
            .popup-close {
                position:unset;
                background-color: transparent;
                border: none;
                cursor: pointer;
                width: 1.81rem;
                height: 1.81rem;
                padding: 0;
                svg {
                    fill:  $c-text-secondary;
                    width: 100%;
                    height: 100%;
                }
                &:hover {
                    svg {
                        fill:  $c-text-primary;
                    }
                }
            }
        }
        .popup-body {
            padding: 1rem;
            .popup-description,
            .popup-subline,
            .popup-loader,
            .popup-footnote {
                font-size: 1rem;
                color: $c-text-secondary;
                text-align: left;
            }
            .popup-subline{
                font-size: 0.8rem;
            }
            .popup-alert {
                font-size: 0.8rem;
                color: $c-accent;
            }
            .popup-footnote{
                font-size: 0.7rem;
            }
            .flexed-buttons{
                display: flex;
                gap : 20px;
                margin-bottom: 1rem;
                .vt-btn{
                    font-size: 1rem
                }
            }
        }
        .popup-footer {
            padding: 1rem;
            &.space-between {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
